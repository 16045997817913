import { Component, OnInit } from '@angular/core';
import { tileLayer, latLng, circle, polygon, marker } from 'leaflet';

@Component({
  selector: 'app-load-view',
  templateUrl: './load-view.component.html',
  styleUrls: ['./load-view.component.scss']
})
export class LoadViewComponent implements OnInit {

    options = {
    layers: [
      tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 1, attribution: '...' })
    ],
    zoom: 5,
    center: latLng(46.879966, -121.726909)
  };

  layers = [
    circle([ 46.95, -122 ], { radius: 5000 }),
    polygon([[ 46.8, -121.85 ], [ 46.92, -121.92 ], [ 46.87, -121.8 ]]),
    marker([ 46.879966, -121.726909 ])
];

  constructor() { }

  ngOnInit() {
  }

  //https://api.mapbox.com/styles/v1/daliya-j/cjtf9zlzr0r1e1fo0xozfkrdo.html?fresh=true&title=true&access_token=pk.eyJ1IjoiZGFsaXlhLWoiLCJhIjoiY2p0Zjl5a21qMDI4MTN5cDhuaGZnOWZkZyJ9.020oBX8wtdF4FsQUmJq_Lw#13.6/37.784020/-122.403944/0

}
