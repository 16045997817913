// angular
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// libs
import { CookieService, CookieModule } from 'ngx-cookie';
import { TransferHttpCacheModule } from '@nguniversal/common';
// shared
import { SharedModule } from '@shared/shared.module';
import { TranslatesService } from '@shared/translates';
// components
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { UniversalStorage } from '@shared/storage/universal.storage';

import { MenubarComponent } from './shared/menubar/menubar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { LoadViewComponent } from './load-view/load-view.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { BlogComponent } from './blog/blog.component';
import { BlogInnerComponent } from './blog-inner/blog-inner.component';
import { CollectionsComponent } from './collections/collections.component';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { NgxCarouselModule } from 'ngx-carousel';
import 'hammerjs';
import { HomePageComponent } from './home-page/home-page.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';


export function initLanguage(translateService: TranslatesService): Function {
  return (): Promise<any> => translateService.initLanguage();
}

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'my-app' }),
    TransferHttpCacheModule,
    HttpClientModule,
    RouterModule,
    AppRoutes,
    BrowserAnimationsModule,
    CookieModule.forRoot(),
    SharedModule.forRoot(),
    NgxCarouselModule,
    ModalModule.forRoot(),
    LeafletModule.forRoot(),
    NgxMapboxGLModule.withConfig({
      accessToken: 'pk.eyJ1IjoiZGFsaXlhLWoiLCJhIjoiY2p0Zjl5a21qMDI4MTN5cDhuaGZnOWZkZyJ9.020oBX8wtdF4FsQUmJq_Lw', // Optionnal, can also be set per map (accessToken input of mgl-map)
    })
  ],
  declarations: [AppComponent, HomePageComponent, MenubarComponent, FooterComponent, 
    LoadViewComponent, AboutUsComponent, BlogComponent, BlogInnerComponent, CollectionsComponent],
  providers: [
    CookieService,
    BsModalService,
    UniversalStorage,
    { provide: APP_INITIALIZER, useFactory: initLanguage, multi: true, deps: [TranslatesService] },
  ],
})
export class AppModule {}
