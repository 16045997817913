import { Component, OnInit , TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.scss']
})
export class MenubarComponent implements OnInit {

  modalRef: BsModalRef;
  constructor(private modalService: BsModalService,private router : Router) {}
 ngOnInit() {
   
 }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  refresh() {
    this.router.navigateByUrl('');
  }
}
