import { Routes, RouterModule } from '@angular/router';
import { MetaGuard } from '@ngx-meta/core';

import { WrapperComponent } from '@shared/layouts/wrapper/wrapper.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { CollectionsComponent } from './collections/collections.component';
import { BlogComponent } from './blog/blog.component';
import { BlogInnerComponent } from './blog-inner/blog-inner.component';
import { LoadViewComponent } from './load-view/load-view.component';
import { HomePageComponent } from './home-page/home-page.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  // {
  //   path: '',
  //   component: HomeComponent
  // },
  { path: '', loadChildren: './home/home.module#HomeModule' },
  {
    path: 'about',
    component: AboutUsComponent
  },
  {
    path: 'collections',
    component: CollectionsComponent
  },
  {
    path: 'blog',
    component: BlogComponent
  },
  {
    path: 'blog-inner',
    component: BlogInnerComponent
  }
];
// must use {initialNavigation: 'enabled'}) - for one load page, without reload
export const AppRoutes = RouterModule.forRoot(routes, { initialNavigation: 'enabled' });
